@tailwind components;
@tailwind utilities;
:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
  --speed: 500ms; 

}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

.nav-product-items-enter {
  position: absolute;
  top: 20vh;
  left: 0;
  z-index: 1;
  transform: translateY(-100%);
}
.nav-product-items-enter-active {
  width: 100%;
  transform: translate(0%);
  transition: all 300ms ease;
  

}
.nav-product-items-exit {
  position: absolute;
  top: 0;
  left: 0;
}
.nav-product-items-exit-active {
  width: 100%;
  transform: translateY(-100%);
  z-index: 1;
  transition: all 300ms ease;
}
.dropdown {
  transition: 0.15s ease;
}

.dropdown.active .arrow {
  transform: rotate(45deg) translate(-5px, -5px);
}

.dropdown.active .arrow:before {
  transform: translate(10px, 0);
}

.dropdown.active .arrow:after {
  transform: rotate(90deg) translate(10px, 0);
}

.input-dropdown.active .arrow-input {
  transform: rotate(45deg) translate(-5px, -5px);
}

.input-dropdown.active .arrow-input:before {
  transform: translate(10px, 0);
}

.input-dropdown.active .arrow-input:after {
  transform: rotate(90deg) translate(10px, 0);
}


.arrow {
  width: 13px;
  height: 17px;
  display: inline-block;
  position: relative;
  bottom: -5px;
  margin-right: 10px;
  transition: 0.4s ease;
  margin-top: 2px;
  text-align: left;
  transform: rotate(45deg);
  float: right;
}

.arrow:before,
.arrow:after {
  position: absolute;
  content: '';
  display: inline-block;
  width: 10px;
  height: 3px;
  background-color: #fff;
  transition: 0.4s ease;
}

.arrow:after {
  position: absolute;
  transform: rotate(90deg);
  top: -5px;
  left: 5px;
}
.arrow-input {
  width: 13px;
  height: 17px;
  display: inline-block;
  position: relative;
  bottom: -5px;
  margin-right: 10px;
  transition: 0.4s ease;
  margin-top: 2px;
  text-align: left;
  transform: rotate(45deg);
  float: right;
}

.arrow-input:before,
.arrow-input:after {
  position: absolute;
  content: '';
  display: inline-block;
  width: 10px;
  height: 3px;
  background-color: #9ca3af;
  transition: 0.4s ease;
}

.arrow-input:after {
  position: absolute;
  transform: rotate(90deg);
  top: -5px;
  left: 5px;
}

.common-input {
 height: 56px !important;
 width: 100% !important;
 background-color: transparent !important;
 border: 1px solid !important;
 border-color: rgb(120 113 108) !important;
 border-radius: 5px !important;
 color: black !important;
}
.common-input.active {
 background-color: rgb(4 47 46) !important;
 border-color: rgb(168 162 158) !important;
}
.common-input:hover{
 border-color: rgb(168 162 158) !important;
}
.common-input:focus{
 border-color: rgb(214 211 209) !important;
}

.common-input-button {
 height: 56px !important;
 background-color: transparent !important;
 border: 1px solid !important;
 border-color: rgb(120 113 108) !important;
 border-radius: 5px !important;
 color: black !important;
}
.common-input-button:hover{
 border-color: rgb(168 162 158) !important;
}
.common-input-button:focus{
 border-color: rgb(214 211 209) !important;
}
.phone-input {
color: black;
background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  ) !important; 
width: 100% !important;
height: 2.8rem !important;
padding-top: 3px !important;
padding-bottom: 3px !important;
font-size: 0.875rem !important;
border: 1px solid #D1D5DB !important;
border-radius: 0.375rem !important;
outline: none !important;
ring-width: 1px !important;
ring-color: #9CA3AF !important;
}
.phone-input-dropdown {
 color: #000;
}
.my-actions { margin: 0 2em; }
.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }

.right-gap {
  margin-right: auto;
}

.fade {
    -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
  }
.grid-container {
  grid-template-columns: 1fr 1fr 1fr;
  background:
    linear-gradient(#000,#000) center/2px 100% no-repeat,
    linear-gradient(#000,#000) center/2px 100% no-repeat;
  background-position:33% 0, 67% 0;
}

.grid-item {
  height: 20px;
  background-color: red;
  position: relative;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

select {
  background-image: url("/src/core/assets/arrow-down.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
}

/* SideMenu */
.side-menu {
  position: absolute;
  top: 0;
  width: 300px;
  right: 0;
  transition: right var(--speed) ease; /* Update the transition property */
  overflow: hidden;
  z-index: 10000 !important;
}

/* CSSTransition classes */
.menu-primary-enter {
  position: absolute;
  
}

.menu-primary-enter-active {
  right: 0;
  transition: all var(--speed) ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  right: -300px; /* Update the target position */
  transition: all var(--speed) ease;
}

.MuiTabs-flexContainer .MuiButtonBase-root:last-child {
  margin-right: 0 !important;
}

/* CSSTransition classes  */

.user-dashboard-menu-enter {
  position: absolute !important;
  transform: translateX(-110%) !important;
}
.user-dashboard-menu-enter-active {
  transform: translateX(0%) !important;
  transition: all var(--speed) ease !important;
}
.user-dashboard-menu-exit {
  
}
.user-dashboard-menu-exit-active {
  transform: translateX(-110%) !important;
  transition: all var(--speed) ease !important;
}

.optionHeading {
  border-bottom: 3px solid #f472b6;
  position: absolute;
  width: 100%;
  height: 30px;
  line-height: 30px;
  top: -30px;
  overflow: hidden;
}
.optionHeading span {
 background-color: #f472b6;
 color: white;
 padding: 0 20px;
 display: inline-block;
 z-index: 1;
 position: relative;
 
}
.optionHeading span::after {
 position: absolute;
 content: "";
 width: 34%;
 height: 100%;
 background-color: #f472b6;
 z-index: -1;
 transform: rotate(45deg);
}
.quantityHeading {
  border-bottom: 3px solid #f472b6;
  position: absolute;
  width: 100%;
  height: 30px;
  line-height: 30px;
  top: -30px;
  overflow: hidden;
}
.quantityHeading span {
 background-color: #f472b6;
 color: white;
 padding: 0 20px;
 display: inline-block;
 z-index: 1;
 position: relative;
 
}
.quantityHeading span::after {
 position: absolute;
 content: "";
 width: 61px;
 height: 100%;
 background-color: #f472b6;
 z-index: -1;
 transform: rotate(45deg);
}
.upload-box {
   background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
         
.btn-grad {
  background-image: linear-gradient(to right, #314755 0%, #26a0da  51%, #314755  100%);
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.may-like-heading {
  position: relative;
  overflow: hidden;
}

.may-like-heading:after {
  content: "";
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 1px solid #d6d3d1;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: #964c47;
  padding: 8px 20px;
  border-radius: 100%;
  color: white;
}
.swiper-button-next::after {
  font-size: 10px !important;
  color: white;
}
.swiper-button-prev::after {
  font-size: 10px !important;
  color: white;
}

@media screen and (max-width: 778px) {
  .swiper-button-next,
  .swiper-button-prev {
  padding: 1px 20px;
  }
  .swiper-button-next::after {
    font-size: 10px;
    color: white;
  }
  .swiper-button-prev::after {
    font-size: 10px;
    color: white;
  }
}
