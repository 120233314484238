

.slider-container {
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  font-size: 50px;
  position: absolute;
  text-align: center;
  top: 40%;
  z-index: 10;
}

.slide-text {
  top: 65%;
  font-size: 2rem;
}



.slider-container:hover .dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* .active-dot {
  background-color: rgba(255, 255, 255, 0.5);
} */

.slider-container:hover .dot{
  background-color: rgba(0, 0, 0, 0.3);
}
.slider-container:hover .active-dot{
  background-color: rgba(255, 255, 255, 0.5);
}

.play-pause {
  color: black;
}